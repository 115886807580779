.reviewsWrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.reviewsWrapper .review {
  background: #e9eefb;
  border-radius: 2px;
  flex-direction: column;
  justify-content: space-between;
  height: 144px;
  padding: 16px 20px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.reviewsWrapper .review:first-of-type {
  width: 241px;
}

.reviewsWrapper .review:nth-of-type(2) {
  width: 284px;
}

.reviewsWrapper .review:nth-of-type(3) {
  width: 235px;
}

.reviewsWrapper .review:nth-of-type(4) {
  width: 189px;
}

.reviewsWrapper .review:nth-of-type(5) {
  width: 251px;
}

.reviewsWrapper .review:nth-of-type(6) {
  width: 309px;
}

.reviewsWrapper .review:nth-of-type(7) {
  width: 315px;
}

.reviewsWrapper .reviewText span {
  background: #ffe2ab;
  font-weight: 700;
}

.reviewsWrapper .author {
  color: #7182b6;
  align-items: center;
  gap: 8px;
  display: flex;
}

.reviewsWrapper .author .authorPhoto, .reviewsWrapper .author .authorPhoto img {
  width: 24px;
  height: 24px;
}

@media only screen and (width <= 959px) {
  .reviewsWrapper {
    flex-direction: column;
  }

  .reviewsWrapper .review:first-of-type, .reviewsWrapper .review:nth-of-type(3), .reviewsWrapper .review:nth-of-type(4), .reviewsWrapper .review:nth-of-type(6) {
    display: none;
  }

  .reviewsWrapper .review:nth-of-type(2), .reviewsWrapper .review:nth-of-type(5), .reviewsWrapper .review:nth-of-type(7) {
    gap: 8px;
    width: auto;
    height: auto;
  }
}
/*# sourceMappingURL=index.a83008a7.css.map */
