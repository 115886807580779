@import '/packages/site/src/public/styles/colors.scss';

.reviewsWrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .review {
    padding: 16px 20px;
    background: $ui_02;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 144px;
    font-size: 12px;
    line-height: 16px;

    &:nth-of-type(1) {
      width: 241px;
    }

    &:nth-of-type(2) {
      width: 284px;
    }

    &:nth-of-type(3) {
      width: 235px;
    }

    &:nth-of-type(4) {
      width: 189px;
    }

    &:nth-of-type(5) {
      width: 251px;
    }

    &:nth-of-type(6) {
      width: 309px;
    }

    &:nth-of-type(7) {
      width: 315px;
    }
  }

  .reviewText span {
    background: $warning_03;
    font-weight: 700;
  }

  .author {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $text_03;
    
    .authorPhoto, .authorPhoto img {
      height: 24px;
      width: 24px;
    }
  }
}

@media only screen and (max-width: 959px) {
  .reviewsWrapper {
    flex-direction: column;

    .review:nth-of-type(1),
    .review:nth-of-type(3),
    .review:nth-of-type(4),
    .review:nth-of-type(6) {
      display: none;
    }

    .review:nth-of-type(2),
    .review:nth-of-type(5),
    .review:nth-of-type(7) {
      width: auto;
      height: auto;
      gap: 8px;
    }
  }
}
